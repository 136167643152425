/* SUIT Light */
@font-face {
  font-family: 'SUIT-Light';
  src: url('../fonts/SUIT-Light.otf') format('opentype');
}

/* SUIT Regular */
@font-face {
  font-family: 'SUIT-Regular';
  src: url('../fonts/SUIT-Regular.otf') format('opentype');
}

/* SUIT Medium */
@font-face {
  font-family: 'SUIT-Medium';
  src: url('../fonts/SUIT-Medium.otf') format('opentype');
}

/* SUIT SemiBold */
@font-face {
  font-family: 'SUIT-SemiBold';
  src: url('../fonts/SUIT-SemiBold.otf') format('opentype');
}

/* SUIT Bold */
@font-face {
  font-family: 'SUIT-Bold';
  src: url('../fonts/SUIT-Bold.otf') format('opentype');
}
