/* Quill */
@import 'react-quill/dist/quill.snow.css';
div.quill {
  padding: 0;
  /* border: none; */
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
}

.ql-editor {
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.ql-editor::-webkit-scrollbar {
  width: 16px;
}
