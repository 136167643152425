@import 'swiper/css';
@import 'swiper/css/effect-creative';

.swiper-pagination {
  @apply absolute z-10 inline-flex py-4 transform -translate-x-1/2 left-1/2 bottom-1;
}

.swiper-pagination-bullet {
  @apply bg-white opacity-40 px-8 h-0.5 inline-flex;
}

.swiper-pagination-bullet-active {
  @apply inline-flex px-8 bg-white opacity-100;
}
